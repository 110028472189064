import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStateContext } from '../../context/contextProvider';

const Heder = () => {
  const {token,setToken}=useStateContext()
  const [user,setUser]=useState(null)
  // const user=JSON.parse(localStorage.getItem('USER'))

  const logOut=(ev)=>{
ev.preventDefault()
setToken(null)
  }

  useEffect(() =>{
    setUser(JSON.parse(localStorage.getItem('USER')))
  },[]);
    return (

        
        <div className="container-fluid nav-bar  ">
        <nav className="navbar navbar-expand-md  fixed-top bg-body navbar-dark  px-4 ">
            <NavLink to="/" className="navbar-brand d-flex align-items-center text-center">
                <div className="mx-3">
                    <img className="img-fluid" src={`../../logo-removebg.png`} alt="Icon" style={{width: '120px',height:'80px'}}/>
                </div>
                <h2 className="m-0 text-primary">LuxeImmoKech</h2>
            </NavLink>


            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse d-md-flex justify-content-md-between" id="navbarCollapse">

                <div className="navbar-nav ms-3 me-5">
                    <NavLink to="/home" className="nav-item nav-link ">Home</NavLink>
                    <NavLink to="/immobilieres" className="nav-item nav-link ">Immobilieres</NavLink>
                    <NavLink to="/about" className="nav-item nav-link ">About</NavLink>
                    <NavLink to="/service" className="nav-item nav-link">Service</NavLink>

                    <NavLink to="/contact" className="nav-item nav-link">Contact</NavLink>
                </div>
                <div className="d-flex mb-sm-3  ">
                    
                    {token?<><NavLink to="/profile" className="btn btn-primary px-3 me-2  d-md-flex">{user?user.profile.nom :''}</NavLink> <NavLink onClick={logOut} className="btn btn-primary px-3  d-md-flex">Log Out</NavLink></>:<><NavLink to="/logIn" className="btn btn-primary px-3 me-2 d-md-flex">log In</NavLink>
                    <NavLink to="/signUp" className="btn btn-primary px-3 me-2 d-md-flex">sign Up</NavLink></>}
                    
                </div>
            </div>
        </nav>
    </div>
    );
}

export default Heder;
