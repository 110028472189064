
import './App.css';

function App() {
  return (
    <div className=" p-0 bg-secondary">
      {/* <Heder/> */}
     

      {/* <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='/about' element={<Abaot/>}/>
        <Route path='/service' element={<Service/>}/>
        <Route path='/logIn' element={<LogInForm/>}/>
        <Route path='/signUp' element={<SignUpForm/>}/>
        <Route path='/detailes/:id' element={<Detailes/>}/>
        <Route path='/contact' element={<ContactForm/>}/>
      </Routes> */}
      {/* <Footer/> */}
      {/* tbZ7mgTwbuu2 */}
      {/* <button className='btn btn-primary'>ok</button> */}
      {/* <Main/> */}
      {/* <Detailes/> */}
      {/* <Rejester/> */}
      {/* <LogInForm/> */}
    </div>
  );
}

export default App;
