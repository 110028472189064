import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from '../context/contextProvider';
import Heder from '../pages/1-header/Heder';


const DefaultLayout = () => {
  const {user,token}=useStateContext()
  if(!token){
    return <Navigate to='/home'/>
  }
  return (
    <div>

      <Heder/>
      <Outlet/>
    
    </div>
  );
}

export default DefaultLayout;
