import React from 'react';
import Heros from '../2-heros/Heros';
import Heder from '../1-header/Heder';
import Footer from '../4-footer/Footer';
import Reviews from '../reviews/Reviews';
const Abaot = () => {
return (
<div>
    <Heder />
    <Heros />
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="about-img position-relative overflow-hidden p-5 pe-0">
                        <img className="img-fluid w-100" src="img/about.jpg"/>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 className="mb-4">#1 Le lieu parfait pour trouver la propriété idéale   </h1>
                    <p className="mb-4">sur notre site Web LuxeImmoKech, vous pouvez trouver de nombreuses propriétés qui répondent à vos normes.
                nous proposons la vente ainsi que la location. nous nous assurons de vous trouver
                ce que vous cherchez chez nous.</p>
                        <p>
                  <i className="bi bi-check text-primary me-3"></i>
                  Location ,l'achat et la vente des villas
                </p>
                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des appartementes
                </p>
                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des terres
                </p>

                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des garages
                </p>
                    {/* <a className="btn btn-primary py-3 px-5 mt-3" href="">Read More</a> */}
                </div>
            </div>
        </div>
    </div>
    <div className="container-xxl py-5">
            <div className="container  bg-secondary p-3 rounded">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px", borderBottom:'3px solid black'}}>
                    <h1 className="mb-3">Property Agents</h1>
                    <p>voici quelques informations sur nos agents pour mieux les connaître.</p>
                </div>
                <hr className='bg-dark'/>
                <div className="row g-4 justify-content-center bg-dark p-2 rounded">
                    


                

                    <div className="col-lg-4 col-md-6 wow fadeInUp bg-secondary p-3 rounded" data-wow-delay="0.1s" style={{borderLeft:'7px solid black'}}>
                        <div className="team-item rounded overflow-hidden">
                            <div className="position-relative">
                                <img className="m-auto d-block w-75 mb-3 rounded" src="img/logo.png" alt=""/>
                                <div
                                    className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                    <a className="btn btn-square mx-1" href=""><i className="bi bi-facebook text-info"></i></a>
                                    <a className="btn btn-square mx-1" href=""><i className="bi bi-twitter text-info"></i></a>
                                    <a className="btn btn-square mx-1" href=""><i className="bi bi-instagram text-danger"></i></a>
                                </div>
                            </div>
                            <div className="text-center p-4 mt-3">
                                <h5 className="fw-bold mb-0">immo MARRAKECH</h5>
                                <small>CIO MANAGER</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Reviews/>
    <Footer />
</div>
);
}

export default Abaot;
