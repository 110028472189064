import React, { useEffect, useState } from 'react';
import Footer from '../4-footer/Footer';
import axios from'axios'

const Profile = () => {
  const [user,setUser]=useState(JSON.parse(localStorage.getItem('USER')))
  const [reservations, setReservations] = useState(null);
  const [achates, setAchates] = useState(null);
  useEffect(() =>{
    setUser(JSON.parse(localStorage.getItem('USER')))
  
      axios.get('https://api.luxeimmokech.com/api/reservations/'+JSON.parse(localStorage.getItem('USER')).profile.id)
    .then((response)=>setReservations(response.data.data))
    
    axios.get('https://api.luxeimmokech.com/api/achates/'+JSON.parse(localStorage.getItem('USER')).profile.id)
    .then((response)=>setAchates(response.data.data))
    
  },[]);
  if(achates){
    console.log(achates);
  }
  return (
  <>
  <div className='container-fluid'>
  <div className=' mt-5 h-25'>

</div>
  <h1 className=' mt-5 pt-5 '>  Salut <span className='text-primary'>{user.profile.nom} {user.profile.prenom}</span>  </h1>
  <div className='  d-lg-flex gap-3 justify-content-between px-5 w-100 '>
            
            
          <div className='row col-lg-3 col-md-6 col-12 mt-5 m-auto  bg-secondary'>
          <div className='d-flex flex-column align-items-center  p-2 rounded'>
              <section className='d-flex bg-primary justify-content-center align-items-center' style={{width:'70px' ,height:'70px',borderRadius:"50%"}}>
              <article>
                <b className=' text-white fs-2 text-uppercase '>
                {user.profile.nom[0]}
                 {user.profile.prenom[0]}
                </b>
                 </article>
              </section>
              <h4>{user.profile.nom} {user.profile.prenom}</h4>
              
              {user?<>
            <table>
              <tr>
                <th className=' text-white'>CIN </th>
                <td>: {user.profile.cin}</td>
              </tr>
              <tr>
                <th className=' text-white'>Telephone </th>
                <td>: {user.profile.tele}</td>
              </tr>
              <tr>
                <th className=' text-white'>Email </th>
                <td>: {user.email}</td>
              </tr>
            </table>
              
              </>:''}
        
            </div>
          </div>
   <div className='col-lg-9 mt-5'>
   <div className="table-responsive  ">
   <h4>les reservations de locations :</h4>
<table className='table table-bordered border-secondary  m-auto table-striped-columns'>
<thead>
 <tr>
   <th>nom immobilier</th>
   <th>date reservation</th>
   <th>date debut</th>
   <th>date fin</th>
   <th>nombers perssonnes</th>
   <th>etate</th>
 
 </tr>
</thead>

<tbody>
{reservations?
reservations.map(rese=><tr key={rese.id}>
 <td>{rese.immobiliere.nom}</td>
 <td>{rese.dateReservation}</td>
 <td>{rese.dateDebut}</td>
 <td>{rese.dateFin}</td>
 <td>{rese.nbPerssonnes}</td>
 <td>{rese.etate==='en cours'? <span className='bg-danger p-2 text-dark rounded'><i className='bi bi-clock'></i></span>:<span className=' p-2 bg-success text-dark rounded '> <i className='bi bi-check'></i></span>}</td>
</tr>)
:''}
</tbody>
</table>
</div>

<div className="table-responsive  mt-5 ">
<h4>les reservations de l'achats :</h4>
<table className='table table-bordered border-secondary   text-center m-auto table-striped-columns'>
<thead>
 <tr>
   <th>nom immobilier</th>
   <th>date reservation</th>

   <th>etate</th>
 
 </tr>
</thead>

<tbody >
{achates?
achates.map(rese=><tr key={rese.id}>
 <td>{rese.immobiliere.nom}</td>
 <td>{rese.dateReservation}</td>

 <td>{rese.etate==='2'? <span className='bg-danger p-2 text-dark rounded'><i className='bi bi-clock'></i></span>:<span className=' p-2 bg-success text-dark rounded '> <i className='bi bi-check'></i></span>}</td>
</tr>)
:''}
</tbody>
</table>
</div>
   </div>
  </div>
  </div>
    <Footer/>
</>
  );
}

export default Profile;
