import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Reviews = () => {
  const [reviews, setReviews] = useState(null);
  const [error, setError] = useState(null);

  const fetchReviews = async (retryCount = 0) => {
    try {
      const response = await axios.get('https://api.luxeimmokech.com/api/reviews');
      setReviews(response.data);
    } catch (err) {
      if (err.response && err.response.status === 429 && retryCount < 3) {
        // Attendre 1 seconde avant de réessayer
        setTimeout(() => fetchReviews(retryCount + 1), 1000);
      } else {
        setError(err);
      }
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  }

  return (
    <div className="py-5 container-xxl">
      <div className="container">
        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
          <h1 className="mb-3">Nos clients disent!</h1>
          <p>
            voici quelques avis de certains de nos chers clients. donnez-nous un petit commentaire afin que nous puissions savoir ce que vous pensez de notre plateforme.
          </p>
        </div>
        <div className="row justify-content-between px-5">
          {reviews && reviews.map(review => (
            <div key={review.id} className="m-2 bg-secondary rounded p-1 col-lg-3 col-sm-8">
              <div className="bg-dark border rounded p-3">
                <p>{review.message}</p>
                <div className="d-flex align-items-center">
                  <img alt='users' className="img-fluid flex-shrink-0 rounded" src="img/prof.png" style={{ width: "45px", height: "45px" }} />
                  <div className="ps-3">
                    <h6 className="fw-bold mb-1">{review.name}</h6>
                    <small>{review.email}</small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='my-4 text-end'>
          <Link className='btn btn-outline-light' to='/contact'>Commenter</Link>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
