import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heros from '../2-heros/Heros';
import Heder from '../1-header/Heder';
import Footer from '../4-footer/Footer';
import { useStateContext } from "../../context/contextProvider";

const Detailes = () => {
  const [immobilier, setImmobilier] = useState(null);
  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { id } = useParams();
  const { token } = useStateContext();
  const nav = useNavigate();

  useEffect(() => {
    axios
      .get(`https://api.luxeimmokech.com/api/immobiliers/${id}`)
      .then((response) => setImmobilier(response.data.data))
      .catch((error) => console.error(error));

    axios
      .get(`https://api.luxeimmokech.com/api/medias/${id}`)
      .then((response) => setImages(response.data.data))
      .catch((error) => console.error(error));
  }, [id]);

  if (!immobilier) {
    nav('/');
  }

  const handleImageClick = (src) => {
    setSelectedImage(src);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Heder />
      <Heros />

      <div className="container">
        <div className="d-md-none" style={{ height: "70px" }}></div>

        <div className="section mt-2 bg-dark p-3">
          <div className="container">
            <div className="row justify-content-between bg-secondary p-3">
              <div className="col-lg-6">
                <img
                  src={`https://api.luxeimmokech.com/storage/${images && images[0].src}`}
                  className="img-fluid"
                  style={{ maxHeight: '250px', width: '100%' }}
                />
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-between">
                  <h2 className="heading text-primary m-3">{immobilier && immobilier.nom}</h2>
                  <div className="bg-primary rounded text-white m-3 py-1 px-3">
                    {immobilier && immobilier.categorie.categorie}
                  </div>
                </div>
                <p className="meta">
                  <span className="bi bi-map-fill text-primary me-2"></span>
                  {immobilier && immobilier.adresse}
                </p>
                <p className="text-dark-50">{immobilier && immobilier.description}</p>
                <div className="d-flex border-top">
                  <small className="flex-fill text-center border-end py-2">
                    <i className="bi bi-bed text-primary me-2"></i>
                    {immobilier && immobilier.nbrChamber} Chambers
                  </small>
                  <small className="flex-fill text-center py-2">
                    <i className="bi bi-bath text-primary me-2"></i>
                    {immobilier && immobilier.nprToilets} Toilets
                  </small>
                </div>
                <div className="mt-5 text-end">
                  <Link to={token ? (immobilier && immobilier.type.type === 'location' ? `/reserve/${id}` : `/achate/${id}`) : '/login'} className="btn btn-primary me-2">
                    {immobilier && immobilier.type.type}
                  </Link>
                  <Link to='/contact' className="btn btn-primary ms-1">Contact Us</Link>
                </div>
              </div>

              <div className="d-flex justify-content-between flex-wrap mt-2 border-top pt-2">
                {images && images.map((imag, i) => (
                  <div key={i} className="col-lg-6 p-3">
                    <img
                      src={`https://api.luxeimmokech.com/storage/${imag.src}`}
                      className="img-fluid"
                      style={{ maxHeight: '250px', width: '100%', cursor: 'pointer' }}
                      alt={imag.src}
                      onClick={() => handleImageClick(imag.src)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal"
            style={{
              display: 'block',
              position: 'fixed',
              top: '70px', // Ajustez cette valeur à la hauteur de votre barre de navigation
              left: 0,
              width: '100%',
              height: 'calc(100% - 70px)', // Hauteur totale moins la hauteur de la navbar
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 1000,
              overflow: 'auto',
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{
                maxWidth: '90%',
                margin: 'auto',
              }}
            >
              <div
                className="modal-content"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  position: 'relative',
                }}
              >
                <button
                  type="button"
                  className="btn-close"
                  style={{
                    position: 'absolute',
                    top: '25px',
                    right: '10px',
                    zIndex: 1010,
                    backgroundColor: 'rgb(225 188 49)',
                    color: '#ffffff',
                    border: '2px solid #ffffff',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize: '1.5rem',
                    outline: 'none',
                  }}
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
                <div className="modal-body" style={{ padding: 0 }}>
                  <img
                    src={`https://api.luxeimmokech.com/storage/${selectedImage}`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: 'calc(100vh - 150px)', // Ajustez la hauteur pour ne pas dépasser l'écran
                      width: 'auto',
                      height: 'auto',
                      display: 'block',
                      margin: 'auto',
                    }}
                    alt="Selected"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Detailes;
