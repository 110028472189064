import React, { useState } from 'react';
import Heros from '../2-heros/Heros';
import Heder from '../1-header/Heder';
import Footer from '../4-footer/Footer';
import axios from 'axios';
import Swal from 'sweetalert2';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [callbackMessage, setCallbackMessage] = useState(null);

  const onButtonClick = (e) => {
    e.preventDefault();

    const review = { email: email, name: name, message: message };

    axios.post('https://api.luxeimmokech.com/api/reviews', review, { withCredentials: true })
      .then(response => setCallbackMessage(response.data.message))
      .catch(error => console.log(error.response.data));

    setName('');
    setEmail('');
    setMessage('');
  };

  if (callbackMessage) {
    Swal.fire({
      position: 'bottom-end',
      zIndex: 1111199991,
      icon: 'success',
      title: callbackMessage,
      showCancelButton: false,
      timer: 3000,
      width: '400px',
    });
  }

  return (
    <>
      <Heder />
      <Heros />
      <div className="container-xxl py-5">
        <div className="container mt-4">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <h1 className="mb-3">Contactez nous</h1>
            <p>si vous avez besoin d'informations, n'hésitez pas à nous contacter.</p>
          </div>
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                {/* Address */}
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="bg-secondary rounded p-3">
                    <div role='button' className="d-flex align-items-center bg-dark rounded p-3" style={{ border: '1px dashed #e4941c' }}>
                      <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                        <i className="bi bi-map text-primary"></i>
                      </div>
                      <span>123 rue molay rchid Marrakech , Maroc</span>
                    </div>
                  </div>
                </div>
                {/* Email */}
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="bg-secondary rounded p-3">
                    <div role='button' className="d-flex align-items-center bg-dark rounded p-3" style={{ border: '1px dashed #e4941c' }}>
                      <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                        <i className="bi bi-envelope-open text-primary"></i>
                      </div>
                      <span>admin@luxeimmokech.com</span>
                    </div>
                  </div>
                </div>
                {/* Phone */}
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="bg-secondary rounded p-3">
                    <div role='button' className="d-flex align-items-center bg-dark rounded p-3" style={{ border: '1px dashed #e4941c' }}>
                      <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                        <i className="bi bi-phone text-primary"></i>
                      </div>
                      <span>Mdm.Rizlane : +212.07.01.78.04.53</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Map */}
            <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <iframe className="position-relative rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d850.9666147255969!2d-7.737854269548461!3d31.44534314253855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDI2JzQzLjIiTiA3wrA0NCcxOC42Ilc!5e0!3m2!1sar!2sma!4v1707649661414!5m2!1sar!2sma" frameBorder="0" style={{ minHeight: '400px', border: '0' }} aria-hidden="false" tabIndex="0"></iframe>
            </div>
            {/* Contact Form */}
            <div className="col-md-6 bg-secondary p-3 rounded">
              <div className="wow fadeInUp" data-wow-delay="0.5s">
                <p className="mb-4">contactez nous en utilisant cette formulaire</p>
                <form onSubmit={onButtonClick}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input value={name} required type="text" onChange={(e) => setName(e.target.value)} className="form-control" id="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input value={email} required type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" placeholder="Your Email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea required className="form-control" onChange={(e) => setMessage(e.target.value)} placeholder="Leave a message here" id="message" style={{ height: '150px' }} value={message}></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">envoyer le Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactForm;
