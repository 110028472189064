import React from "react";

const Heros = () => {
  return (
    <article className="mt-5 px-2" id="carousel">
      <div className="m-5" style={{ height: "20px" }}></div>
      <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"></div>

      <div className="bd-example-snippet bd-code-snippet">
        <div className="bd-example m-0 border-0">
          <div
            id="carouselExampleCaptions"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="../../img/carousel-1.jpg"
                  alt=""
                  className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                  width={800}
                  height={520}
                />

                <div className="carousel-caption d-none d-md-block">
                  <h5>Villa</h5>
                  <p>location , avent et achat</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="../../img/carousel-3.jpg"
                  alt=""
                  className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                  width={800}
                  height={520}
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Apartement</h5>
                  <p>location , avent et achat .</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="../../img/carousel-2.jpg"
                  alt=""
                  className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                  width={800}
                  height={520}
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Terre</h5>
                  <p>location , avent et achat</p>
                </div>
              </div>

              <div className="carousel-item">
                <img
                  src="../../img/carousel-2.jpg"
                  alt=""
                  className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                  width={800}
                  height={520}
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Garage</h5>
                  <p>location , avent et achat</p>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Heros;
