import React, { useEffect, useState } from "react";
import Footer from "../4-footer/Footer";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
const Achate = () => {
  const user = JSON.parse(localStorage.getItem("USER"));
  const [immobilier, setImmobilier] = useState(null);
  const [images, setImages] = useState(null);
  const [achate,setAchat] = useState(null);
  const idUser = user.profile.id;
  const { idImmobilier } = useParams();



  const handleAchter= ()=>{
    const achate={
      profile_id:idUser,
      immobiliere_id:idImmobilier
    }
    axios.post('https://api.luxeimmokech.com/api/achates',achate)
    .then(response=>setAchat(response.data.message))

    
  }
  useEffect(() => {
    axios
      .get(`https://api.luxeimmokech.com/api/immobiliers/${idImmobilier}`)
      .then((response) => setImmobilier(response.data.data))
      .catch((error) => console.error(error));

    axios
      .get(`https://api.luxeimmokech.com/api/medias/${idImmobilier}`)
      .then((response) => setImages(response.data.data))
      .catch((error) => console.error(error));
  }, []);
  // console.log(immobilier);
  if(achate){
    alert(achate)
  }
  return (
    <main className=" bg-dark ">
      {/* <h1>{Message}</h1> */}
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center   g-lg-5 py-5 bg-secondary">
        <h4 className=" text-body-emphasis text-center text-primary mb-3">
              Donnes de reservation
            </h4>
          
            <div className="d-inline col-lg-6" >
              <h5>Donnes de client</h5>
              <table>
                <tr>
                  <th>Nom : </th>
                  <td>
                    <span className="text-primary">{user.profile.nom}</span>
                  </td>
                </tr>
                <tr>
                  <th>Prenom : </th>
                  <td>
                    <span className="text-primary">{user.profile.prenom}</span>
                  </td>
                </tr>
                <tr>
                  <th>Tele : </th>
                  <td>
                    <span className="text-primary">{user.profile.tele}</span>
                  </td>
                </tr>
                <tr>
                  <th>Email : </th>
                  <td>
                    <span className="text-primary">{user.email}</span>
                  </td>
                </tr>

                <tr>
                  <th>
                  </th>
                  <td>
                    <button onClick={handleAchter} className="btn btn-outline-success mt-2">Acheter</button>
                  </td>
                </tr>
              </table>
            </div>

            <div className="d-inline col-lg-6">
              <h5 className="mt-3">Donnes d'immobiliere</h5>

              {immobilier && (
                <div
                  className="col-lg-12 col-md-8 wow fadeInUp bg-secondary border border-3 p-3"
                  data-wow-delay="0.1s"
                >
                  <div className="property-item rounded overflow-hidden">
                    <div className="position-relative overflow-hidden">
                      <Link to={`/detailes/${idImmobilier}`} className="hova">
                        <img
                          className="img-fluid"
                          style={{ maxHeight: "212px", width: "100%" }}
                          src={`https://api.luxeimmokech.com/storage/${
                            images != null &&
                            images.filter(
                              (med) => med.immobiliere_id === immobilier.id
                            )[0].src
                          }`}
                          alt={
                            images != null &&
                            images.filter(
                              (med) => med.immobiliere_id === immobilier.id
                            )[0].src
                          }
                        />
                      </Link>
                      <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                        {immobilier.type.type}
                      </div>
                      <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                        {immobilier.categorie.categorie}
                      </div>
                    </div>
                    <div className="p-4 pb-0">
                      <h6 className="text-primary mb-3">
                        {immobilier.type.type === "location" ? (
                          <>
                            {" "}
                            <span className="text-white">Prix par mois :</span>
                            {immobilier.prix} ${" "}
                            <span className="text-white">Prix par Jour :</span>
                            {immobilier.prixParJour} $
                          </>
                        ) : (
                          ""
                        )}
                      </h6>
                      <Link
                        to={`/detailes/${immobilier.id}`}
                        className="d-block h5 mb-2 hova"
                      >
                        {immobilier.nom}
                      </Link>
                      <p>
                        <i className="fa fa-map-marker-alt text-primary me-2"></i>
                        {immobilier.adresse}
                      </p>
                    </div>
                    <div className="d-flex border-top">
                      <small className="flex-fill text-center border-end py-2">
                        <i className="fa fa-bed text-primary me-2"></i>
                        {immobilier.nbrChamber} Bed
                      </small>
                      <small className="flex-fill text-center py-2">
                        <i className="fa fa-bath text-primary me-2"></i>
                        {immobilier.nprToilets} Bath
                      </small>
                    </div>
                  </div>
                </div>
              )}
            </div>
        
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Achate;
