import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
      <div className="container-fluid bg-dark text-white-50 footer border-top border-secondary pt-3  mt-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container ">
          <div className="row g-6 ">
              <div className="col-lg-6 col-md-6 bg-secondary p-4 rounded mb-2 " style={{borderLeft:'4px solid #000'}}>
                  <h5 className="text-white mb-4">mettre en contact</h5>
                  <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 rue molay rchid Marrakech , Maroc</p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>Mdm.Rizlane : +212.07.01.78.04.53</p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>M.Lamrani   : +212.07.11.12.56.91</p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>M.Anouar    : +212.06.99.53.20.02</p>
                  <p className="mb-2"><i className="fa fa-envelope me-3"></i>admin@luxeimmokech.com</p>
                  <div className="d-flex pt-2">
                      <a className="btn btn-outline-light btn-social" href=""><i className="bi bi-twitter"></i></a>
                      <a className="btn btn-outline-light btn-social" href=""><i className="bi bi-facebook"></i></a>
                      <a className="btn btn-outline-light btn-social" href=""><i className="bi bi-youtube"></i></a>
                      <a className="btn btn-outline-light btn-social" href=""><i className="bi bi-linkedin"></i></a>
                  </div>
              </div>
              <div className="col-lg-6 col-md-6 bg-secondary p-4 rounded mb-2 " style={{borderLeft:'4px solid #000'}}>
                  <h5 className="text-white mb-4">Liens rapides</h5>
                  <Link className="btn btn-link text-white-50" to="/">accueil</Link>
                  <Link className="btn btn-link text-white-50" to="/about">à propos de nous</Link>
                  <Link className="btn btn-link text-white-50" to="/cotact">Contactez nous</Link>
                  <Link className="btn btn-link text-white-50" to="/service"> Services</Link>
                  
              </div>
          
          
          </div>
      </div>
      <div className="container">
          <div className="copyright">
              <div className="row">
                  <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                      &copy; <Link className="border-bottom text-primary" href="#">LuxeImmoKech</Link>, All Right Reserved. 
        
        Designed By <a className="border-bottom" href="/">Time LuxeImmoKech</a>
                  </div>
                  <div className="col-md-6 text-center text-md-end">
                      <div className="footer-menu">
                          <Link to="/">accueil</Link>
                  
                          <Link to="/contact">Help</Link>
                        
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default Footer;
