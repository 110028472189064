import { createBrowserRouter } from "react-router-dom";
import LogInForm from "./pages/5-login/Login";
import SignUpForm from "./pages/6-signUp/SignUp";
import Main from "./pages/3-main/Main";
import P404 from "./404";
import DefaultLayout from "./composent/defaultLayout";
import GestLayout from "./composent/gestLayout";
import Profile from "./pages/11-profile/Profile";
import Detailes from "./pages/10-detailles/Detailes";
import Service from "./pages/8-service/Service";
import ContactForm from "./pages/9-contact/Contact";
import Abaot from "./pages/7-aboat/Abaot";
import Location from "./pages/12-reserver/Location";
import Achate from "./pages/12-reserver/Achate";
import Immobiliers from "./pages/13-immobilier/Immobilieres";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },

      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/reserve/:idImmobilier",
        element: <Location />,
      },
      {
        path: "/achate/:idImmobilier",
        element: <Achate />,
      },
    ],
  },

  {
    path: "/",
    element: <GestLayout />,
    children: [
      {
        path: "/home",
        element: <Main />,
      },

      {
        path: "/signUp",
        element: <SignUpForm />,
      },
      {
        path: "/signUp",
        element: <SignUpForm />,
      },
      {
        path: "/login",
        element: <LogInForm />,
      },
      {
        path: "/signUp",
        element: <SignUpForm />,
      },
    ],
  },
  {
    path: "*",
    element: <P404 />,
  },

  {
    path: "/detailes/:id",
    element: <Detailes />,
  },
  {
    path: "/service",
    element: <Service />,
  },
  {
    path: "/immobilieres",
    element: <Immobiliers />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/about",
    element: <Abaot />,
  },
  {
    path: "/detailes/:id",
    element: <Detailes />,
  },
]);
export default router;
