import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Heros from "../2-heros/Heros";
import Heder from "../1-header/Heder";
import Footer from "../4-footer/Footer";
import Reviews from "../reviews/Reviews";
const Main = () => {

  
  const [immobiliers, setImmobiliers] = useState([]);
  const [images, setImages] = useState(null);
  const [categorieFilter, setCategorieFilter] = useState(0);
  const [typeFilter, setTypeFilter] = useState(0);


  // filtrer immobiliers par categorie
  const filteredByCategory = () => {
    if (immobiliers) {
      if (categorieFilter === 0) {
        return immobiliers;
      } else if (categorieFilter === 1) {
        return immobiliers.filter((item) => item.categorie_id === 1);
      } else if (categorieFilter === 2) {
        return immobiliers.filter((item) => item.categorie_id === 2);
      } else if (categorieFilter === 3) {
        return immobiliers.filter((item) => item.categorie_id === 3);
      } else if (categorieFilter === 4) {
        return immobiliers.filter((item) => item.categorie_id === 4);
      }
    }
  };


  // filtrer immobiliers par type (location / achat)
  const filterImmobiliers = () => {
    if (filteredByCategory()) {
      if (typeFilter === 0) {
        return filteredByCategory();
      } else if (typeFilter === 1) {
        return filteredByCategory().filter(
          (immobilier) => immobilier.type_id === 1
        );
      } else if (typeFilter === 2) {
        return filteredByCategory().filter(
          (immobilier) => immobilier.type_id === 2
        );
      }
    }
  };

  useEffect(() => {
    axios
      .get("https://api.luxeimmokech.com/api/index")
      .then((response) => setImmobiliers(response.data.data))
      .catch((error) => console.error(error));

    axios
      .get("https://api.luxeimmokech.com/api/medias")
      .then((response) => setImages(response.data.data))
      .catch((error) => console.error(error));
  }, []);


  return (
    <>
      <Heder />
      <Heros />
      <div className="container-fluid">
        <div className="container-xxl py-5 bg-dark mt-2 ">
          <div className="container bg-secondary mt-2 p-3">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="about-img position-relative overflow-hidden p-5 pe-0">
                  <img
                    className="img-fluid w-100"
                    src="../../img/about.jpg"
                    alt="about img"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 wow fadeIn bg-dark p-3 rounded"
                data-wow-delay="0.5s"
              >
                <h1 className="mb-4 text-primary">
                Le lieu parfait pour trouver la propriété idéale  
                </h1>
                <p className="mb-4">
                sur notre site Web LuxeImmoKech, vous pouvez trouver de nombreuses propriétés qui répondent à vos normes.
                nous proposons la vente ainsi que la location. nous nous assurons de vous trouver
                ce que vous cherchez chez nous.
                </p>
                <p>
                  <i className="bi bi-check text-primary me-3"></i>
                  Location ,l'achat et la vente des villas
                </p>
                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des appartementes
                </p>
                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des terres
                </p>

                <p>
                  <i className="bi bi-check text-primary me-3"></i>Location ,l'achat et la vente des garages
                </p>
                <Link className="btn btn-primary py-3 px-5 mt-3" to="/about">
                  Plus
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="container-fluid bg-dark mt-4 pb-3 pt-2 ">
            <div
              className="text-center mx-auto  wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "500px" }}
            >
              <h1 className="mb-3">Types de propriétés</h1>
              <p>
              vous trouverez ici les propriétés que nous proposons et que nous possédons
              </p>
            </div>
            <div className="row  justify-content-between">
              <div
                className="col-lg-2 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <a
                  role="button"
                  onClick={() => setCategorieFilter(0)}
                  className="cat-item d-block bg-secondary text-center rounded p-2"
                >
                  <div className="rounded p-4 bg-dark">
                    <div className="icon mb-3">
                      <img
                        className="img-fluid"
                        src="../../img/icon-apartment.png"
                        alt="Icon"
                      />
                    </div>
                    <h6>All</h6>
                    <span className="text-primary">{immobiliers.length} Proprites</span>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-2 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <a
                  role="button"
                  onClick={() => setCategorieFilter(1)}
                  className="cat-item d-block bg-secondary text-center rounded p-2"
                >
                  <div className="rounded p-4 bg-dark">
                    <div className="icon mb-3">
                      <img
                        className="img-fluid"
                        src="../../img/icon-apartment.png"
                        alt="Icon"
                      />
                    </div>
                    <h6>Villa</h6>
                    <span className="text-primary">{immobiliers&&immobiliers.filter(immobilier=>immobilier.categorie_id===1).length} Proprites</span>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-2 col-sm-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <a
                  role="button"
                  className="cat-item d-block bg-secondary text-center rounded p-2"
                  onClick={() => setCategorieFilter(2)}
                >
                  <div className="rounded p-4 bg-dark">
                    <div className="icon mb-3">
                      <img
                        className="img-fluid"
                        src="../../img/icon-villa.png"
                        alt="Icon"
                      />
                    </div>
                    <h6>Appartement</h6>
                    <span className="text-primary">{immobiliers&&immobiliers.filter(immobilier=>immobilier.categorie_id===2).length} Proprites</span>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-2 col-sm-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <a
                  role="button"
                  className="cat-item d-block bg-secondary text-center rounded p-2"
                  onClick={() => setCategorieFilter(3)}
                >
                  <div className="rounded p-4 bg-dark">
                    <div className="icon mb-3 ">
                      <img
                        className="img-fluid"
                        src="../../img/icon-neighborhood.png"
                        alt="Icon"
                      />
                    </div>
                    <h6>Terres</h6>
                    <span className="text-primary">{immobiliers&&immobiliers.filter(immobilier=>immobilier.categorie_id===3).length} Proprites</span>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-2 col-sm-6 wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <a
                  role="button"
                  className="cat-item d-block bg-secondary text-center rounded p-2"
                  onClick={() => setCategorieFilter(4)}
                >
                  <div className="rounded p-4 bg-dark">
                    <div className="icon mb-3">
                      <img
                        className="img-fluid"
                        src="../../img/icon-luxury.png"
                        alt="Icon"
                      />
                    </div>
                    <h6>Garage</h6>
                    <span className="text-primary">{immobiliers&&immobiliers.filter(immobilier=>immobilier.categorie_id===4).length} Proprites</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className=" py-3">
          <div className="container-fluid ">
            <div className="row g-0 gx-5 align-items-end mb-3 pt-3 bg-dark">
              <div className="col-lg-6 ">
              
                <div
                  className="p-3 ms-2  wow slideInLeft"
                  data-wow-delay="0.1s"
                >
                  <h1 className="mb-3 ">Types de reservation</h1>
                  <p>Vous souhaitez acheter ou louer ? choisissez-vous.</p>
                </div>
              </div>
              <div
                className="col-lg-6 text-start text-lg-end wow slideInRight"
                data-wow-delay="0.1s"
              >
                <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                  <li className="nav-item me-2">
                    <a
                      className={`${
                        typeFilter == 0 && "active"
                      } btn btn-primary`}
                      onClick={() => setTypeFilter(0)}
                    >
                      Tout
                    </a>
                  </li>
                  <li className="nav-item me-2">
                    <button
                      className={`${
                        typeFilter === 1 && "active"
                      } btn btn-primary`}
                      onClick={() => setTypeFilter(1)}
                    >
                      Louer
                    </button>
                  </li>
                  <li className="nav-item me-2">
                    <button
                      className={`${
                        typeFilter === 2 && "active"
                      } btn btn-primary`}
                      onClick={() => setTypeFilter(2)}
                    >
                      Acheter
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row g-0 gx-5 bg-dark p-5">
              {filterImmobiliers() != null &&
                filterImmobiliers().map((immobilier) => (
                  <div
                    key={immobilier.id}
                    className="col-lg-4 col-md-6 wow fadeInUp bg-secondary border border-3 p-3"
                    data-wow-delay="0.1s"
                  >
                    <div className="property-item rounded overflow-hidden">
                      <div className="position-relative overflow-hidden">
                        <Link
                          to={`/detailes/${immobilier.id}`}
                          className="hova"
                        >
                          <img
                            className="img-fluid"
                            style={{ maxHeight: "212px", width: "100%" }}
                            src={`https://api.luxeimmokech.com/storage/${
                              images != null &&
                              images.filter(
                                (med) => med.immobiliere_id === immobilier.id
                              )[0].src
                            }`}
                            alt="immobilier"
                          />
                        
                        </Link>
                        <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                          {immobilier.type.type}
                        </div>
                        <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                          {immobilier.categorie.categorie}
                        </div>
                      </div>
                      <div className="p-4 pb-0">
                      <p className="text-primary mb-3">
                        {immobilier.type.type==='location'?<> <span className="text-white">Prix par mois :</span>{immobilier.prix} <span className="text-white"> MAD</span> <span className="text-white"> <br /> Prix par Jour :</span>{immobilier.prixParJour} <span className="text-white"> MAD</span></>:<><span className="text-white"> Prix : </span>{immobilier.prix} <span className="text-white"> MAD</span></>}

                        </p>
                        <Link
                          to={`/detailes/${immobilier.id}`}
                          className="d-block h5 mb-2 hova"
                        >
                          {immobilier.nom}
                        </Link>
                        <p>
                          <i className="fa fa-map-marker-alt text-primary me-2"></i>
                          {immobilier.adresse}
                        </p>
                      </div>
                      <div className="d-flex border-top">
                        <small className="flex-fill text-center border-end py-2">
                          <i className="fa fa-bed text-primary me-2"></i>
                          {immobilier.nbrChamber} Chambres
                        </small>
                        <small className="flex-fill text-center py-2">
                          <i className="fa fa-bath text-primary me-2"></i>
                          {immobilier.nprToilets} Toiletes
                        </small>
                      </div>
                    </div>
                  </div>
                ))}
                <Link className="btn btn-primary" to='/immobilieres'>Plus d'immobilieres</Link>
            </div>

          </div>
          <Reviews/>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Main;
