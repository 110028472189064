import React from 'react';
import Heros from '../2-heros/Heros';
import Heder from '../1-header/Heder';
import Footer from '../4-footer/Footer';
import Reviews from '../reviews/Reviews';

const Service = () => {
    return (
        <>
          <Heder/>
           <Heros/>
           <div className="container-xxl mt-4  ">
            <div className="container ">
                <div className="text-center mx-auto  wow fadeInUp p-4" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                    <h1 className="mb-3">Nos services </h1>
                    <p>notre site Web LuxeImmoKech propose de nombreux services, vous pouvez les trouver ici</p>

                        <button className="btn btn-primary px-3 me-2 ">Location </button> <button  className="btn btn-primary px-3 me-2 ">Achtere</button>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 bg-secondary p-3 rounded col-sm-6  wow fadeInUp" style={{borderLeft:'8px solid black'}} data-wow-delay="0.1s">
                        <a className="cat-item d-block bg-dark text-center rounded p-3" href="">
                            <div className="rounded p-4 bg-secondary">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src="img/icon-apartment.png" alt="Icon"/>
                                </div>
                                <h6>Appartement</h6>
                                <span className='text-primary'>location | achat | vente </span>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3 bg-secondary p-3 rounded col-sm-6  wow fadeInUp" style={{borderLeft:'8px solid black'}} data-wow-delay="0.3s">
                        <a className="cat-item d-block  bg-dark text-center rounded p-3" href="">
                            <div className="rounded p-4 bg-secondary">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src="img/icon-villa.png" alt="Icon"/>
                                </div>
                                <h6>Villa</h6>
                                <span className='text-primary'>location | achat | vente </span>
                            </div>
                        </a>
                    </div>
                   
                    <div className="col-lg-3 bg-secondary p-3 rounded col-sm-6  wow fadeInUp" style={{borderLeft:'8px solid black'}} data-wow-delay="0.3s">
                        <a className="cat-item d-block  bg-dark text-center rounded p-3" href="">
                            <div className="rounded p-4 bg-secondary">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src="img/icon-neighborhood.png" alt="Icon"/>
                                </div>
                                <h6>Terres</h6>
                                <span className='text-primary'>location | achat | vente </span>
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-lg-3 bg-secondary p-3 rounded col-sm-6  wow fadeInUp" style={{borderLeft:'8px solid black'}} data-wow-delay="0.7s">
                        <a className="cat-item d-block  bg-dark text-center rounded p-3" href="">
                            <div className="rounded p-4  bg-secondary">
                                <div className="icon mb-3 ">
                                    <img className=" img-fluid" src="img/icon-luxury.png" alt="Icon"/>
                                </div>
                                <h6>Garage</h6>
                                <span className='text-primary'>location | achat | vente </span>
                            </div>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
        <Reviews/>
           <Footer/>
    </>
    );
}

export default Service;
