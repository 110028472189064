import React, { useEffect, useState } from "react";
import Footer from "../4-footer/Footer";
import { useStateContext } from "../../context/contextProvider";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Location = () => {
  const user = JSON.parse(localStorage.getItem("USER"));

  const [immobilier, setImmobilier] = useState(null);
  const [images, setImages] = useState(null);
  const idUser = user.profile.id;
  const { idImmobilier } = useParams();

  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [nbPerssonnes, setNbPerssonnes] = useState("");
  const [cin, setCin] = useState("");
  const [reservationsrolation_id, setReservationsrolationId] = useState("");
  const [response, setResponse] = useState(null);

  const handelReserve = (e) => {
    e.preventDefault();
    const reservation = {
      dateDebut: dateDebut,
      dateFin: dateFin,
      nbPerssonnes: nbPerssonnes,
      reservationsrolation_id: reservationsrolation_id,
      profile_id: idUser,
      immobiliere_id: idImmobilier,
      cin: cin,
    };

    setDateDebut("");
    setCin("");
    setDateFin("");
    setNbPerssonnes("");
    setCin("");
    if (dateDebut >= dateFin) {
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Date début doit être antérieure à la date de fin",
        showCancelButton: false,
        timer: 3000,
        width: "400px",
      });
    } else {
      try {
        axios
          .post("https://api.luxeimmokech.com/api/reservations", reservation)
          .then((response) => setResponse(response.data))
          .catch((error) => console.log(error.response.data.message));

        Swal.fire({
          position: "bottom-end",
          icon: "success",
          title: "Réservation effectuée avec succès",
          showCancelButton: false,
          timer: 3000,
          width: "400px",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`https://api.luxeimmokech.com/api/immobiliers/${idImmobilier}`)
      .then((response) => setImmobilier(response.data.data))
      .catch((error) => console.error(error));

    axios
      .get(`https://api.luxeimmokech.com/api/medias/${idImmobilier}`)
      .then((response) => setImages(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <main className=" bg-dark ">
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center  g-lg-5 py-5 bg-secondary">
          <div className="col-lg-5 text-center text-lg-start mt-sm-5">
            <h4 className=" text-body-emphasis text-center text-primary mb-3">
              Données de réservation
            </h4>
            <h5>Données de client</h5>
            <table>
              <tr>
                <th>Nom : </th>
                <td>
                  <span className="text-primary">{user.profile.nom}</span>
                </td>
              </tr>
              <tr>
                <th>Prénom : </th>
                <td>
                  <span className="text-primary">{user.profile.prenom}</span>
                </td>
              </tr>
              <tr>
                <th>Télé : </th>
                <td>
                  <span className="text-primary">{user.profile.tele}</span>
                </td>
              </tr>
              <tr>
                <th>Email : </th>
                <td>
                  <span className="text-primary">{user.email}</span>
                </td>
              </tr>

              <tr>
                <th></th>
                <td></td>
              </tr>
            </table>
            <h5 className="mt-3">Données d'immobilière</h5>

            {immobilier && (
              <div
                className="col-lg-12 col-md-8 wow fadeInUp bg-secondary border border-3 p-3"
                data-wow-delay="0.1s"
              >
                <div className="property-item rounded overflow-hidden">
                  <div className="position-relative overflow-hidden">
                    <Link to={`/detailes/${idImmobilier}`} className="hova">
                      <img
                        className="img-fluid"
                        style={{ maxHeight: "212px", width: "100%" }}
                        src={`https://api.luxeimmokech.com/storage/${
                          images != null &&
                          images.filter(
                            (med) => med.immobiliere_id === immobilier.id
                          )[0].src
                        }`}
                        alt={
                          images != null &&
                          images.filter(
                            (med) => med.immobiliere_id === immobilier.id
                          )[0].src
                        }
                      />
                    </Link>
                    <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      {immobilier.type.type}
                    </div>
                    <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                      {immobilier.categorie.categorie}
                    </div>
                  </div>
                  <div className="p-4 pb-0">
                    <h6 className="text-primary mb-3">
                      {immobilier.type.type === "location" ? (
                        <>
                          <span className="text-white">Prix par mois :</span>
                          {immobilier.prix} $ <span className="text-white">Prix par Jour :</span>
                          {immobilier.prixParJour} $
                        </>
                      ) : (
                        ""
                      )}
                    </h6>
                    <Link
                      to={`/detailes/${immobilier.id}`}
                      className="d-block h5 mb-2 hova"
                    >
                      {immobilier.nom}
                    </Link>
                    <p>
                      <i className="fa fa-map-marker-alt text-primary me-2"></i>
                      {immobilier.adresse}
                    </p>
                  </div>
                  <div className="d-flex border-top">
                    <small className="flex-fill text-center border-end py-2">
                      <i className="fa fa-bed text-primary me-2"></i>
                      {immobilier.nbrChamber} Bed
                    </small>
                    <small className="flex-fill text-center py-2">
                      <i className="fa fa-bath text-primary me-2"></i>
                      {immobilier.nprToilets} Bath
                    </small>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-10 mx-auto col-lg-7 mt-4 bg-dark py-4 ">
            <form
              className="p-4 p-md-5 border rounded-3 bg-secondary"
              onSubmit={handelReserve}
            >
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingNom"
                  required
                  placeholder="2022-03-22"
                  name="dateDebut"
                  value={dateDebut}
                  onChange={(e) => setDateDebut(e.target.value)}
                />
                <label htmlFor="floatingName">Date début</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingPrenom"
                  required
                  placeholder="2023-02-22"
                  name="dateFin"
                  value={dateFin}
                  onChange={(e) => setDateFin(e.target.value)}
                />
                <label htmlFor="floatingSurname">Date fin</label>
              </div>

              <div className="d-flex gap-3 justify-content-between">
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingEmail"
                    required
                    placeholder="3"
                    name="nbPerssonnes"
                    value={nbPerssonnes}
                    onChange={(e) => setNbPerssonnes(e.target.value)}
                  />
                  <label htmlFor="floatingEmail">Nombre de personnes</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingTele"
                    required
                    placeholder="EE12345"
                    name="cin"
                    value={cin}
                    onChange={(e) => setCin(e.target.value)}
                  />
                  <label htmlFor="floatingPhone">CIN</label>
                </div>
              </div>
              <div className="form-floating mb-3">
                <select
                  id="floatingPhone"
                  name="reservationsrolation_id"
                  value={reservationsrolation_id}
                  onChange={(e) => setReservationsrolationId(e.target.value)}
                  className=" form-select"
                  required
                >
                  <option value="">Sélectionner </option>
                  <option value="1">Famille</option>
                  <option value="2">Amis</option>
                  <option value="3">Employés</option>
                </select>
                <label htmlFor="floatingPhone">Relation</label>
              </div>

              <input
                className="w-100 btn btn-lg btn-primary"
                value="Réserver"
                type="submit"
              />

              <hr className="my-4" />
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Location;
