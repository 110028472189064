import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const SignUpForm = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tele, setTele] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState('');

  const nav = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      nom,
      prenom,
      email,
      password,
      tele,
    };

    if (password === confirmPassword) {
      axios
        .post("https://api.luxeimmokech.com/api/register", user)
        .then((response) => {
          console.log(response.data); // Pour déboguer
      
          if (response.status === 200 ) {
            Swal.fire({
              icon: 'success',
              title: 'Compte créé avec succès',
              text: 'Compte créé avec succès',
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              nav('/login');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: 'Erreur lors de la création du compte.',
            });
          }
        })
        .catch((error) => {
          console.log(error.response); // Pour déboguer
          setError(error.response?.data?.message || 'Une erreur est survenue.');
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: error.response?.data?.message || 'Une erreur est survenue.',
          });
        });
    } else {
      Swal.fire({
        position: 'bottom-end',
        zIndex: 1111199991,
        icon: 'error',
        title: 'Le mot de passe est incorrect',
        showCancelButton: false,
        timer: 3000,
        color: 'red',
        backgroundColor: 'red',
        width: '400px',
      });
    }
  };

  return (
    <main className="bg-dark">
      <div className="container col-xl-10 col-xxl-8 px-4 py-5 mt-5">
        <div className="row align-items-center g-lg-5 py-5 bg-secondary">
          <div className="col-lg-5 text-center text-lg-start mt-sm-5">
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis text-primary mb-3">
              Sign Up Form
            </h1>
            <p className="col-lg-10 fs-4">
              Veuillez remplir ce formulaire avec vos informations pour vous connecter à notre
              plate-forme.
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-6 mt-4 bg-dark py-4">
            <form
              className="p-4 p-md-5 border rounded-3 bg-secondary"
              onSubmit={handleSubmit}
            >
              <div className="d-flex gap-3 justify-content-between">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingNom"
                    required
                    placeholder="Tainit"
                    name="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                  <label htmlFor="floatingName">Nom</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingPrenom"
                    required
                    placeholder="Abelilah"
                    name="prenom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                  <label htmlFor="floatingSurname">Prenom</label>
                </div>
              </div>
              <div className="d-flex gap-3 justify-content-between">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingEmail"
                    required
                    placeholder="name@example.com"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingEmail">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="floatingTele"
                    required
                    placeholder="0650505050"
                    name="tele"
                    value={tele}
                    onChange={(e) => setTele(e.target.value)}
                  />
                  <label htmlFor="floatingPhone">Num de telephone</label>
                </div>
              </div>
              <div className="d-flex gap-3 justify-content-between">
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    required
                    placeholder="passs"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingConfirmPassword"
                    placeholder="passs"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <label htmlFor="floatingConfirmPassword">Confirm Password</label>
                </div>
              </div>
              <input className="w-100 btn btn-lg btn-primary" value="Sign up" type="submit" />
              <hr className="my-4" />
              <small className="">
                En cliquant sur S'inscrire, vous acceptez les conditions d'utilisation.
              </small>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignUpForm;
