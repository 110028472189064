import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/contextProvider";
import Swal from "sweetalert2";
import { TailSpin } from 'react-loader-spinner'; // Import the spinner

function LogInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [respo, setRenpo] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const { setToken, setUser } = useStateContext();

  const onButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading animation

    const user = { email: email, password: password };
    try {
      const response = await axios.post("https://api.luxeimmokech.com/api/login", user);
      setRenpo(response.data);
      setLoading(false); // Stop loading animation

      if (response.data && response.data.status !== "error") {
        localStorage.setItem("USER", JSON.stringify(response.data.data));
        setUser(response.data.data);
        setToken(response.data.data.token);
      } else {
        Swal.fire({
          position: 'bottom-end',
          zIndex: 1111199991,
          icon: 'error',
          title: (response.data ? response.data.message : "Unknown error"),
          showCancelButton: false,
          timer: 3000,
          width: '400px',
        });
      }
    } catch (error) {
      setLoading(false); // Stop loading animation
      Swal.fire({
        position: 'bottom-end',
        zIndex: 1111199991,
        icon: 'error',
        title: (error.response ? error.response.data.message : "Unknown error"),
        showCancelButton: false,
        timer: 3000,
        width: '400px',
      });
    }
  };

  return (
    <main className=" bg-dark ">
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5 bg-secondary">
          <div className="col-lg-7 text-center text-lg-start mt-sm-5">
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis text-primary mb-3">
              Log In Form
            </h1>
            <p className="col-lg-10 fs-4">
              remplissez ce formulaire si vous êtes déjà membre pour vous connecter à votre compte
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5 bg-dark py-4">
            <form className="p-4 p-md-5 border rounded-3 bg-secondary" onSubmit={onButtonClick}>
              <div className="form-floating mb-3">
                <input
                  required
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  required
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="checkbox mb-3">
                <label>
                  <input type="checkbox" value="remember-me" /> Remember me
                </label>
              </div>
              <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={loading}>
                {loading ? (
                  <TailSpin
                    height="24"
                    width="24"
                    color="#ffffff"
                    ariaLabel="loading"
                  />
                ) : (
                  "Log in"
                )}
              </button>
              <hr className="my-4" />
              <small className="">
                si vous n'avez pas de compte, s'il vous plaît{" "}
                <b>
                  <Link className="text-primary" to={"/signUp"}>
                    sign up
                  </Link>
                </b>{" "}
                .
              </small>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default LogInForm;
