import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from '../context/contextProvider';
import Heder from '../pages/1-header/Heder';

const GestLayout = () => {
  const {token}=useStateContext()
  if(token){
    return <Navigate to='/'/>
  }
  return (
    <div>
      <Heder/>
      <Outlet/>
    
    </div>
  );
}

export default GestLayout;
